/* Built In Imports */
import { useCallback, useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import axios from 'axios';

/* Internal Imports */
/* Components */
import Loader from '@components/Utility/Loader';
import config from '@config';
import ProgramData from './ProgramData';

/* Services */
// import { consoleLog } from '@components/Utility/Shared/SharedService';

/**
 * Renders the Program Data UI component.
 *
 * @param {string} id - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} Program Data UI component.
 */

const ProgramDataUI = ({ id, region, lang }) => {
  const [loading, setLoading] = useState(true);
  const [programData, setProgramData] = useState([]);
  const [error, setError] = useState('');

  const fetchProgramData = useCallback(async () => {
    axios
      .get(
        `${config.SCHEDULE_API_PATH}?option=com_program&v=2&format=json&task=details&program_id=${id}`
      )
      .then(res => {
        setError();
        setProgramData(res?.data);
        setLoading(false);
      })
      .catch(err => {
        setError('error');
        setProgramData([]);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    fetchProgramData();
    return () => {
      setProgramData();
      setError();
    };
  }, [fetchProgramData]);

  if (loading) {
    return (
      <Box p="30px">
        <Loader pos="relative" />
      </Box>
    );
  }

  if (error || !programData || !programData.length) {
    return (
      <Box as="section" className="page-wrap container-article" bg="#fff">
        <Box>
          <Box
            w={{ md: '632px', sm: '432px', base: '302px' }}
            minW="302px"
            maxW="632px"
            m="0 auto"
            d="grid"
            placeItems="center"
            // bg="#f4f4f4"
            p={{
              md: '51px 69px 57px 70px',
              sm: '45px 15px 54px',
              base: '45px 15px 54px',
            }}
            textAlign="center"
          >
            <Box
              as="p"
              pb="20px"
              fontSize={{ md: '18px', sm: '16px', base: '16px' }}
              fontFamily={'FedraSansStd-medium'}
              lineHeight={{ md: '1.56', sm: '1.5', base: '1.5' }}
              textAlign="center"
              color={'#464038'}
              className="userMessage"
            >
              <style global jsx>
                {`
                  .userMessage p {
                    line-height: 1.56;
                    letter-spacing: normal;
                    text-align: center;
                    color: #cf4520;
                  }
                `}
              </style>
              Oops! Something went wrong! This program is not found in our
              system. Help us improve your experience by sending an error
              report.
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      as="section"
      id="centers-section"
      className="container-article btn-center"
      bg="#fff"
    >
      {/* {loading ? (
        <div>loading</div>
      ) : error ? (
        <div>{error}</div>
      ) : programData && programData.length ? ( */}
      <ProgramData
        data={programData[0]}
        programId={id}
        region={region}
        lang={lang}
      />
      {/* // ) : (
      //   'Error while processing data'
      // )} */}
    </Box>
  );
};

export default ProgramDataUI;
