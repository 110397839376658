/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Link, textDecoration } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { ContainerUI, DescriptionDesign, LinkUI } from './ui';

/* Services */

/**
 * Renders the Program Data component.
 *
 * @param {object} data - Content from api.
 * @returns {ReactElement} Program Data component.
 */

const IS_DEV = config.ENV_NAME === 'local';
const GOOGLE_MAPS_API = 'AIzaSyDNbyG0SQv9P_uKXTKmtfAgVQIcHorAvnk';

const ProgramData = ({ data, programId, region, lang }) => {
  useEffect(() => {
    document.title = data?.title || 'Program Details';
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box>
      <Box
        maxH="340px"
        padding={{ base: '20px', md: '40px' }}
        boxSizing="border-box"
        display="grid"
        alignContent="center"
        bgColor="#faf7f0"
        clipPath="polygon(0 0, 100% 0%, 100% 100%, 0 80%)"
      >
        <Box
          as="h1"
          fontFamily="FedraSansStd-A-medium"
          fontSize={{ base: '28px', md: '32px' }}
          fontWeight="500"
          fontStyle="normal"
          lineHeight="1.5"
          letterSpacing="normal"
          textAlign="left"
          color="#cf4520"
          mb="30px"
        >
          {data?.title}
        </Box>
      </Box>
      <Box
        padding={{ base: '0px 15px', md: '10px 40px' }}
        boxSizing="border-box"
      >
        <ContainerUI key={nanoid()}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
            gridRowGap="15px"
          >
            {data && data.date ? (
              <Content heading="Date:" des={data?.date} />
            ) : null}
            {data && data.session && data.session.length ? (
              <Box>
                <Box>Session Time:</Box>
                {data?.session?.map(session => {
                  return <Box key={nanoid()}>{session}</Box>;
                })}
              </Box>
            ) : null}
            {data && data.language && data.language !== 'unspecified' ? (
              <Content heading="Language:" des={toTitleCase(data.language)} />
            ) : null}
            {data && data.gender && data.gender !== 'unspecified' ? (
              <Content heading="Gender:" des={toTitleCase(data.gender)} />
            ) : null}
          </Box>
        </ContainerUI>
        <ContainerUI
          key={nanoid()}
          display="grid"
          gridTemplateColumns={isMobile ? '1fr' : '49fr 1px 49fr'}
          gridRowGap={isMobile ? '15px' : null}
        >
          <Box
            w="100%"
            display="flex"
            flexDir="column"
            style={{ rowGap: '15px' }}
          >
            {/** lat long takes precendence over map_link */}
            {/* {data && (data.latlong || data.map_link) ? (
              <Box
                as="iframe"
                maxW={{ md: '90%', sm: '100%', base: '100%' }}
                height="350px"
                allowFullScreen
                frameBorder="0"
                referrerpolicy="no-referrer-when-downgrade"
                // src={
                //   data.latlong
                //     ? googleMapsLatLongToEmbedLatLong(data?.latlong)
                //     : googleMapsURLToEmbedURL(data?.map_link)
                // }
                src={googleMapsURLToEmbedURL(data?.map_link)}
              />
            ) : null} */}
            {data && data.address ? (
              <Box maxW="90%" flex="0 0 auto">
                <Box
                  fontFamily="FedraSansStd-medium"
                  fontSize="16px"
                  lineHeight="1.5"
                  textAlign="left"
                  pb="10px"
                >
                  Location:
                </Box>
                <DescriptionDesign
                  key={nanoid()}
                  text={data?.address}
                  canHaveHtml
                />
              </Box>
            ) : null}
            {data && data.map_link ? (
              <LinkUI
                fontFamily="FedraSansStd-book"
                key={nanoid()}
                text="Get Directions"
                href={data.map_link}
                target="_blank"
              />
            ) : null}
          </Box>
          <Box borderLeft="1px solid #d4c9b4" />
          <Box
            w="100%"
            pl={isMobile ? null : '30px'}
            display="flex"
            flexDir="column"
            style={{ rowGap: '15px' }}
          >
            {data?.amount &&
              data?.amount.length &&
              data.amount.map(fee => {
                let des = `${fee?.amount} ${data?.currency}`;
                return (
                  (parseInt(fee?.amount) > 0 ||
                    parseInt(fee?.amount.replace('$', '')) > 0) && (
                    <FlexContent
                      key={nanoid()}
                      heading={fee?.name || 'Program Fee:'}
                      des={des}
                    />
                  )
                );
              })}
            {parseInt(data?.earlyBird) ? (
              <FlexContent
                key={nanoid()}
                heading={`Early Bird Offer: (${data?.earlybird_date})`}
                des={`${data?.earlybird_fee} ${data?.currency}`}
              />
            ) : null}
            {!!data?.registration_enabled || !!data?.enquiry_enabled ? (
              <RegistrationActive
                key={nanoid()}
                registerUrl={data.register_url}
                enquiryUrl={data.enquiry_url}
                registrationEnabled={!!data?.registration_enabled}
                enquiryEnabled={!!data?.enquiry_enabled}
                registrationType={data?.registration_type}
                programId={programId}
                region={region}
                lang={lang}
              />
            ) : (
              <Box
                display="grid"
                placeContent="center"
                bgColor="#fbe6c4"
                height="100px"
              >
                <Box
                  fontFamily={'FedraSansStd-medium'}
                  fontSize="16px"
                  lineHeight="1.5"
                  textAlign="left"
                  letterSpacing="normal"
                  color="#464038"
                  textTransform="capitalize"
                >
                  {data?.registration_closed_text}
                </Box>
              </Box>
            )}
            {parseInt(data?.is_residential_program) ? (
              <SubTextUI
                key={nanoid()}
                href={data?.resident_link}
                linkText="Learn More"
                subText={data?.resident_text}
              />
            ) : null}
            {!!parseInt(data?.is_residential_program) && data?.arrive_by ? (
              <Box
                display="flex"
                flexDir="row"
                fontFamily="FedraSansStd-book"
                fontSize="16px"
                lineHeight={{ base: '1.14' }}
                color={'#28231e'}
                fontWeight="normal"
                fontStretch="normal"
                fontStyle="normal"
                letterSpacing="normal"
              >
                <Box>Arrive By:</Box>
                <Box ml="5px">{data?.arrive_by}</Box>
              </Box>
            ) : null}
            {!!parseInt(data?.is_residential_program) &&
            data?.departing_time ? (
              <Box
                display="flex"
                flexDir="row"
                fontFamily="FedraSansStd-book"
                fontSize="16px"
                lineHeight={{ base: '1.14' }}
                color={'#28231e'}
                fontWeight="normal"
                fontStretch="normal"
                fontStyle="normal"
                letterSpacing="normal"
              >
                <Box>Program Ends:</Box>
                <Box ml="5px">{data?.departing_time}</Box>
              </Box>
            ) : null}
            {data?.cancellation_link || data?.subtext || data?.cme_text ? (
              <Box
                display="grid"
                gridRowGap="8px"
                fontFamily="FedraSansStd-book"
              >
                {data?.cancellation_link ? (
                  <LinkUI
                    key={nanoid()}
                    href={data.cancellation_link}
                    text={`Cancellation ${'&'} Refund Policy`}
                    target="_blank"
                  />
                ) : null}
                {data?.subtext ? (
                  <SubTextUI
                    key={nanoid()}
                    linkText="Learn More"
                    subText={data?.subtext}
                  />
                ) : null}
                {data?.cme_text ? (
                  <SubTextUI
                    key={nanoid()}
                    href={data?.cme_link ? data?.cme_link : null}
                    linkText="Learn More"
                    subText={data?.cme_text}
                  />
                ) : null}
              </Box>
            ) : null}
          </Box>
        </ContainerUI>
        {data && data.override_session_timings ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              Timings {`&`} Session Information:
            </Box>
            <DescriptionDesign
              text={data.override_session_timings}
              canHaveHtml
            />
            {data.intro_talk ? (
              <DescriptionDesign
                key={nanoid()}
                text={data.intro_talk}
                canHaveHtml
              />
            ) : null}
          </ContainerUI>
        ) : null}
        {data && data.description ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              Description:
            </Box>
            <DescriptionDesign
              key={nanoid()}
              text={data.description}
              canHaveHtml
            />
          </ContainerUI>
        ) : null}
        {data && data.prerequisites ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              Prerequisites:
            </Box>
            <DescriptionDesign
              key={nanoid()}
              text={data.prerequisites}
              canHaveHtml
            />
          </ContainerUI>
        ) : null}
        {data && data.details ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              Program Guidelines:
            </Box>
            <DescriptionDesign key={nanoid()} text={data.details} canHaveHtml />
          </ContainerUI>
        ) : null}
        {data && data.feeDetails ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              Program Fees and Registration:
            </Box>
            <DescriptionDesign
              key={nanoid()}
              text={data?.feeDetails}
              canHaveHtml
              ml="20px"
              textAlign={'left'}
              letterSpacing="normal"
            />
          </ContainerUI>
        ) : null}
        {/* {data && data.shuttle_text ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              Shuttle Services:
            </Box>
            <DescriptionDesign
              key={nanoid()}
              text={
                <>
                  {data?.shuttle_text}{' '}
                  {data?.shuttle_link ? (
                    <LinkUI
                      key={nanoid()}
                      href={data.shuttle_link}
                      text="Learn More"
                    />
                  ) : null}
                </>
              }
              textAlign={'left'}
              letterSpacing="normal"
            />
          </ContainerUI>
        ) : null} */}
        {data && data.contacts && data.contacts.length && (data.support_portal != '' || data.contacts[0]) ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              For Support:
            </Box>
            {data.support_portal ? (
              <Box
                fontFamily="FedraSansStd-book"
                display="flex"
                flexDir={{ md: 'row', sm: 'column', base: 'column' }}
                alignItems={{ md: 'center', sm: 'normal', base: 'normal' }}
                marginBottom="14px"
              >
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  lineHeight={{ sm: '1.5', base: '1.43' }}
                  color={{ sm: '#28231e', base: '#000' }}
                  fontStretch="normal"
                  fontStyle="normal"
                  letterSpacing="normal"
                  textAlign="left"
                >
                  Support Portal Link:
                </Box>
                <Box
                  marginLeft={{ md: '10px', sm: '0', base: '0' }}
                  fontFamily="FedraSansStd-book"
                  lineHeight={{ base: '1.14' }}
                  color={'#28231e'}
                  fontWeight="normal"
                  fontStretch="normal"
                  fontStyle="normal"
                  letterSpacing="normal"
                  textAlign="left"
                >
                  <Link href={data.support_portal} textDecoration={'underline'}>
                    {data.support_portal}
                  </Link>
                </Box>
              </Box>
        ) : null}
            <Box
              fontFamily="FedraSansStd-book"
              fontSize="16px"
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(280px, 1fr))"
              gridColumnGap="30px"
            >
              <ContactInfo key={nanoid()} contact={data.contacts[0]} />
            </Box>
          </ContainerUI>
        ) : null}
        {data && data.volunteer_text ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
          >
            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              lineHeight="1.5"
              textAlign="left"
            >
              Volunteering:
            </Box>
            <DescriptionDesign
              key={nanoid()}
              text={
                <>
                  {data?.volunteer_text}{' '}
                  {data?.volunteer_link ? (
                    <LinkUI
                      key={nanoid()}
                      href={data.volunteer_link}
                      text="Learn More"
                    />
                  ) : null}
                </>
              }
              textAlign={'left'}
              letterSpacing="normal"
            />
          </ContainerUI>
        ) : null}
        {data &&
        data.contacts &&
        data.contacts.length &&
        data.contacts.length > 1 ? (
          <ContainerUI
            key={nanoid()}
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="15px"
            hideBorder="true"
          >
            <OfflineQueries key={nanoid()} contacts={data?.contacts} />
          </ContainerUI>
        ) : null}
      </Box>
      <Box
        height="155px"
        padding="40px"
        boxSizing="border-box"
        display="grid"
        alignContent="center"
        bgColor="#faf7f0"
        clipPath="polygon(0 0, 100% 30%, 100% 100%, 0 100%)"
      />
    </Box>
  );
};

const Content = ({ heading, des }) => {
  return (
    <Box>
      <Box
        as="h5"
        fontFamily={'FedraSansStd-book'}
        fontSize={{ sm: '14px', base: '12px' }}
        fontWeight="normal"
        fontStretch="normal"
        fontStyle="normal"
        lineHeight={{ sm: '1.43', base: '1.67' }}
        letterSpacing="normal"
        textAlign="left"
        color="#28231e"
      >
        {heading}
      </Box>
      <Box
        as="h6"
        fontFamily={'FedraSansStd-medium'}
        fontSize={{ sm: '20px', base: '16px' }}
        fontWeight={'500'}
        fontStretch={'normal'}
        fontStyle={'normal'}
        lineHeight={{ sm: '1.7', base: '1.5' }}
        letterSpacing={'normal'}
        textAlign={'left'}
        color="#28231e"
        textTransform="capitalize"
      >
        {des}
      </Box>
    </Box>
  );
};

const FlexContent = ({ heading, des }) => {
  return (
    <Box
      display={{ md: 'block', sm: 'flex', base: 'flex' }}
      flexDir={{ md: 'column', sm: 'row', base: 'row' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      w="auto"
      // m={{ base: '0 auto', sm: '0 auto', md: '0' }}
    >
      <Box
        as="h5"
        fontFamily={'FedraSansStd-book'}
        fontSize={{ md: '14px', sm: '16px' }}
        lineHeight="1.43"
        textAlign="left"
        color="#28231e"
      >
        {heading}
      </Box>
      <Box
        as="h6"
        fontFamily={'FedraSansStd-medium'}
        fontSize={{ md: '18px', sm: '20px' }}
        lineHeight="1.7"
        textAlign="left"
        color="#28231e"
        fontWeight={'500'}
        pl={{ md: '0', sm: '12px', base: '12px' }}
        textTransform="capitalize"
      >
        {des}
      </Box>
    </Box>
  );
};

const OfflineQueries = ({ contacts }) => {
  const [expand, setExpand] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    setExpand(prev => setExpand(!prev));
  };

  return (
    <Box display="grid" gridRowGap="15px" padding="20px 0">
      <Box
        fontFamily="FedraSansStd-book"
        fontSize="16px"
        lineHeight="1.5"
        color="#cf4520"
        fontWeight="600"
        width="fit-content"
        cursor="pointer"
        userSelect="none"
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center" gridGap="4px">
          Offline Queries and Registration
          {expand ? (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              height="1.5em"
              width="1.5em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              height="1.5em"
              width="1.5em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </Box>
      </Box>
      {expand && contacts && contacts.length > 1 ? (
        <Box
          fontFamily="FedraSansStd-book"
          fontSize="16px"
          display={expand ? 'grid' : 'none'}
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
          gridColumnGap="30px"
        >
          {contacts && contacts?.length
            ? contacts.map((contact, i) => {
                if (i === 0) return;
                return <ContactInfo key={nanoid()} contact={contact} />;
              })
            : 'No Contact Info available'}
        </Box>
      ) : null}
    </Box>
  );
};

const ContactInfo = ({ contact }) => {
  return (
    <Box pb="30px">
      {/* {contact?.contact_name ? (
        <Box
          fontFamily="FedraSansStd-book"
          fontSize="16px"
          lineHeight="1.5"
          display="flex"
          flexDir="row"
          mb={2}
        >
          <Box fontFamily="FedraSansStd-medium">{contact?.contact_name}</Box>
        </Box>
      ) : null} */}
      {contact?.contact_email ? (
        <Box
          fontFamily="FedraSansStd-book"
          display="flex"
          flexDir={{ md: 'row', sm: 'column', base: 'column' }}
          alignItems={{ md: 'center', sm: 'normal', base: 'normal' }}
          marginBottom="14px"
        >
          <Box
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            lineHeight={{ sm: '1.5', base: '1.43' }}
            color={{ sm: '#28231e', base: '#000' }}
            fontStretch="normal"
            fontStyle="normal"
            letterSpacing="normal"
            textAlign="left"
          >
            Email:
          </Box>
          <Box
            marginLeft={{ md: '10px', sm: '0', base: '0' }}
            fontFamily="FedraSansStd-book"
            lineHeight={{ base: '1.14' }}
            color={'#28231e'}
            fontWeight="normal"
            fontStretch="normal"
            fontStyle="normal"
            letterSpacing="normal"
            textAlign="left"
          >
            <a href={`mailto:${contact?.contact_email}`}>
              {contact?.contact_email}
            </a>
          </Box>
        </Box>
      ) : null}
      {contact?.contact_phone ? (
        <Box fontFamily="FedraSansStd-book" display="flex" alignItems="center">
          <Box
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            lineHeight={{ sm: '1.5', base: '1.43' }}
            color={{ sm: '#28231e', base: '0000' }}
            fontStretch="normal"
            fontStyle="normal"
            letterSpacing="normal"
            textAlign="left"
          >
            Helpline:
          </Box>
          <Box
            marginLeft={{ md: '10px', sm: '0', base: '1' }}
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            lineHeight={{ base: '1.14' }}
            color={'#28231e'}
            fontWeight="normal"
            fontStretch="normal"
            fontStyle="normal"
            letterSpacing="normal"
            textAlign="left"
          >
            <a href={`tel:${contact?.contact_phone}`}>
              {contact?.contact_phone}
            </a>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

const RegistrationActive = ({
  registrationEnabled,
  enquiryEnabled,
  registerUrl,
  enquiryUrl,
  programId,
  registrationType,
  region,
  lang,
}) => {
  const register_url = registerUrl ? new URL(registerUrl) : null;
  const enquiry_url = enquiryUrl ? new URL(enquiryUrl) : null;

  return (
    <Box display="grid" gridTemplateColumns="1fr" gridGap="15px">
      <Box display="flex" gridGap={5} flexDir={{ base: 'column', sm: 'row' }}>
        {registrationEnabled ? (
          <NextLink
            href={
              registrationType === 'iframe'
                ? refineUrlDomain(`${config.BASE_PATH}/in/en/program-embed-register?id=${programId}`)
                : refineUrlDomain(
                    IS_DEV
                      ? `${config.BASE_PATH}/in/en${register_url.pathname}`
                      : registerUrl,
                    region,
                    lang
                  )
            }
            passHref
            legacyBehavior>
            <Link
              // display="grid"
              // placeContent="center"
              // width="200px"
              fontWeight="500"
              fontFamily="FedraSansStd-medium"
              fontSize="18px"
              bgColor="#cf4520"
              textAlign="center"
              padding="10px 30px"
              boxSizing="border-box"
              borderRadius="3px"
              color="#faf7f0"
              textDecoration="none"
              _hover={{
                textDecoration: 'none',
                bg: '#000054',
              }}
              target="_blank"
            >
              Register Now
            </Link>
          </NextLink>
        ) : null}
        {enquiryEnabled ? (
          <NextLink
            href={refineUrlDomain(
              IS_DEV
                ? `${config.BASE_PATH}/in/en${enquiry_url.pathname}`
                : enquiryUrl,
              region,
              lang
            )}
            passHref
            legacyBehavior>
            <Link
              fontWeight="500"
              fontFamily="FedraSansStd-medium"
              fontSize="18px"
              bgColor="#faf7f0"
              textAlign="center"
              padding="10px 30px"
              boxSizing="border-box"
              borderRadius="3px"
              color="#cf4520"
              border="1px solid #cf4520"
              textDecoration="none"
              _hover={{
                textDecoration: 'none',
                bg: '#cf4520',
                color: '#faf7f0',
              }}
              target="_blank"
            >
              I want more Information
            </Link>
          </NextLink>
        ) : null}
      </Box>
    </Box>
  );
};

const SubTextUI = ({
  subText,
  fs = '12px',
  href = null,
  linkText,
  ...props
}) => {
  return (
    <Box
      fontFamily="FedraSansStd-book"
      fontStyle="italic"
      lineHeight="1.5"
      fontSize="small"
      textAlign="left"
      color="#464038"
      {...props}
    >
      {subText} {href ? <LinkUI href={href} fs={fs} text={linkText} /> : null}
    </Box>
  );
};

//helpers
// ? this is also added somewhere else too, can't remember
const toTitleCase = str =>
  str.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );

const googleMapsLatLongToEmbedLatLong = latLong => {
  return ` https://www.google.com/maps/embed/v1/place?q=${latLong}&key=${GOOGLE_MAPS_API}`;
};

const googleMapsURLToEmbedURL = mapUrl => {
  const coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(mapUrl);
  if (coords != null) {
    var coordsArray = coords[1].split(',');
    return (
      'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d' +
      coordsArray[1] +
      '!3d' +
      coordsArray[0] +
      '!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098'
    );
  } else {
    const url = new URL(mapUrl);
    let searchParams = new URLSearchParams(url.search);
    const q = searchParams.get('q');

    let finalUrl = `https://www.google.com/maps/embed/v1/place?q=${q}&key=${GOOGLE_MAPS_API}`;
    return finalUrl;
  }
};

export default ProgramData;
